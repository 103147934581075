import { Environment } from "./types";

export const DEV_COLLECTOR_URL = 'https://no-tv2-dev1.mini.snplow.net';

export const STAGE_COLLECTOR_URL = 'https://no-tv2-qa1.collector.snplow.net';

export const PROD_COLLECTOR_URL = 'https://t.tv2.no';


export function getCollectorUrl(env: Environment): string {
  switch (env) {
    case 'local':
    case 'test':
    case 'dev':
    case 'development':
      return DEV_COLLECTOR_URL;
    case 'qa':
    case 'stage':
    case 'staging':
      return STAGE_COLLECTOR_URL;
    case 'prod':
    case 'production':
      return PROD_COLLECTOR_URL;
    default:
      return DEV_COLLECTOR_URL;
  }
}
