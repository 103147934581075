import { Platform } from "@snowplow/browser-tracker";

export function isIOS(): boolean {
  return !!window?.webkit?.messageHandlers?.getAppId;
}

export function isAndroid(): boolean {
  return typeof window.TV2JSBridge !== 'undefined' && typeof window.TV2JSBridge?.getAppId === 'function';
}

export function getPlatform(defaultPlatform: Platform): Platform {
  return (isIOS() || isAndroid()) ? "app" : defaultPlatform;
}

export async function getAppId(defaultAppId: string): Promise<string> {
  if (isIOS()) {
    return await getIOSAppId() || defaultAppId;
  } else if (isAndroid()) {
    return await getAndroidAppId() || defaultAppId;
  } else {
    return defaultAppId;
  }
}

async function getIOSAppId(): Promise<string | null> {
  if (window.webkit?.messageHandlers?.getAppId) {
    const response = await window.webkit.messageHandlers.getAppId.postMessage('getAppId');
    return response !== '' ? response : null;
  }
  return null;
}

async function getAndroidAppId(): Promise<string | null> {
  if (window.TV2JSBridge?.getAppId) {
    return window.TV2JSBridge.getAppId();
  }
  return null;
}
