import { trackSelfDescribingEvent, CommonEventProperties, SelfDescribingJson } from '@snowplow/browser-tracker';
// Automatically generated by Snowtype

/**
 * Page entity for tv2.no
 */
export type Tv2NoPage = {
    /**
     * Is the page a front page or not.
     */
    is_front_page: boolean;
    /**
     * Example: Sport, underholdning, nyheter
     */
    section?: null | string;
    /**
     * The second element of the page path if it exists, eg. tv2.no/sport/fotball/eliteserien ->
     * fotball
     */
    sub_section_level_1?: null | string;
    /**
     * The third element of the page path if it exists, eg. tv2.no/sport/fotball/eliteserien ->
     * eliteserien
     */
    sub_section_level_2?: null | string;
}

/**
 * Schema for tracking user navigation between videos.
 */
export type Tv2NoVideoNavigationEvent = {
    /**
     * The label or identifier of the video navigated to.
     */
    videoLabel: string;
}

/**
 * A global user entity that should be added to all events tracked, either as a global
 * context or individually for each event
 */
export type Tv2User = {
    /**
     * Unique id for a profile. Indicates the profile used when the event happened. If the user
     * is not logged in or profile is not selected, the value should be NULL. The field can
     * excluded for domains that don't use profile-info
     */
    profile_id?: null | string;
    /**
     * Unique id for the user. Customer number on both Play and tv2.no. If the user is not
     * logged in, the value should be set to NULL
     */
    user_id: null | string;
}

/**
 * Context schema for video metadata.
 */
export type Tv2NoVideo = {
    /**
     * The unique asset identifier for the video content.
     */
    assetId: string;
    /**
     * The name of the asset
     */
    assetName: string;
    /**
     * The type of action associated with the video category.
     */
    categoryTypeOfAction?: null | string;
    /**
     * Number of videos a user has watched in a session
     */
    numberOfVideosWatched: number;
    /**
     * The published date of the clip
     */
    publishedDate?: null | string;
    /**
     * The category of the video content.
     */
    videoCategory: string;
    /**
     * Indicates if the video content is live.
     */
    videoLive: boolean;
    /**
     * The name of the video content being played.
     */
    videoName: string;
    /**
     * Id generated for the current video session
     */
    videoSessionId: string;
    /**
     * Tags associated with the video content.
     */
    videoTags: string[];
}

/**
 * Context schema for video player info.
 */
export type Tv2NoVideoPlayer = {
    /**
     * The name of the video player.
     */
    videoPlayerName: VideoPlayerName;
    /**
     * Version of the video player.
     */
    videoPlayerVersion: string;
}

/**
 * The name of the video player.
 */
export type VideoPlayerName = "TV 2 Web" | "TV 2 Klipp" | "SUMO";

/**
 * Schema for tracking user actions
 */
export type Tv2NoUserAction = {
    /**
     * Often the text of a button (or an ARIA-label), or the title of revealed content. The
     * label denotes the alternative that the user answered, voted for or otherwise selected.
     * Examples include '21', 'USD', someones's name.
     */
    actionLabel?: string;
    /**
     * What the user does; usually an active verb. Avoid vague and generic terms ('click' and
     * 'scroll'). Good interaction names: Sort, Vote, Answer. For navigation: Link, Close, Back.
     * View events can be simple ('Seen by user'), but can include: 'On mount' (w/labels:
     * 'Full'/'Partial'/'Outside viewport') or 'Scroll' (w/labels: 'scrolled in'/'scrolled out').
     */
    actionName?: string;
    /**
     * 'View' events are passive, used when something intersects the viewport, such as entering
     * or leaving the visible area. 'Navigation' is for events that makes the URL path change.
     * 'Interaction' is everything else.
     */
    actionType: ActionType;
}

/**
 * 'View' events are passive, used when something intersects the viewport, such as entering
 * or leaving the visible area. 'Navigation' is for events that makes the URL path change.
 * 'Interaction' is everything else.
 */
export type ActionType = "interaction" | "navigation" | "view";

/**
 * Schema for custom components, such as BITS modules or internally developed React
 * components that are likely to be used across multiple page types, views and domains.
 */
export type Tv2Component = {
    /**
     * Usually what the component is named in Github or BITS. Examples: 'Conflict Map', 'Ranked
     * List', 'Social Sharing'.
     */
    component: string;
    /**
     * Usually the content title or a description of the component's content (if the content can
     * be variable). Examples: 'Israel/Palestina Mai 2024' (for Conflict Map), 'Eliteserien Topp
     * 50' (for Ranked List). Not needed for components that don't support variable content.
     */
    componentTitle?: string;
}

/**
 * Schema for event when video ends
 */
export type Tv2NoVideoEnd = {
}

/**
 * Schema for tracking when a user clicks or taps the close button.
 */
export type Tv2NoVideoCloseButtonEvent = {
    /**
     * The category or type of action.
     */
    categoryTypeOfAction: string;
    /**
     * The total number of videos watched by the user in the current session.
     */
    numberOfVideosWatchedInSession: number;
}

/**
 * Context schema for video event info.
 */
export type Tv2NoVideoEvent = {
    /**
     * The type of action associated with the video category.
     */
    categoryTypeOfAction?: null | string;
    /**
     * The published date of the clip
     */
    publishedDate?: null | string;
    /**
     * The category of the video content.
     */
    videoCategory: string;
    /**
     * Indicates if the video content is live.
     */
    videoLive: boolean;
    /**
     * The name of the video content being played.
     */
    videoName: string;
    /**
     * The unique program identifier for the video content.
     */
    videoProgramId?: null | string;
    /**
     * Tags associated with the video content.
     */
    videoTags?: null | string;
}

/**
 * Schema for tracking various types of video interactions, including pause and resume
 * actions.
 */
export type Tv2NoVideoInteraction = {
    /**
     * The unique identifier for the video asset.
     */
    assetId: string;
    /**
     * The name of the video asset.
     */
    assetName: string;
    /**
     * The category type of action associated.
     */
    categoryTypeOfAction: string;
    /**
     * The type of interaction (e.g., 'pause', 'resume').
     */
    interactionType: InteractionType;
}

/**
 * The type of interaction (e.g., 'pause', 'resume').
 */
export type InteractionType = "pause" | "resume";

/**
 * Schema for tracking match click in Livesport
 */
export type Tv2NoLivesportMatchClick = {
    /**
     * Component context of match that is clicked
     */
    component: Component;
    /**
     * Id of match that is clicked
     */
    matchId: string;
    /**
     * Name of match that is clicked
     */
    matchName: string;
}

/**
 * Component context of match that is clicked
 */
export type Component = "lagform" | "horisontal kampliste" | "kampwidget" | "kampliste" | "innbyrdes oppgjør" | "spilte kamper, spiller";

/**
 * Context schema for Livesport events
 */
export type Tv2NoLivesport = {
    /**
     * The id of the page on which the event was triggered
     */
    pageId?: string;
    /**
     * The name of the page on which the event was triggered
     */
    pageName?: string;
    /**
     * The page on which the event was triggered
     */
    pageType: PageType;
    /**
     * The sport on which the event was triggered
     */
    sport: Sport;
    /**
     * The sub page on which the event was triggered
     */
    subPageType?: SubPageType;
}

/**
 * The page on which the event was triggered
 */
export type PageType = "kamper" | "lag" | "turneringer" | "spillere" | "trenere" | "malshow";

/**
 * The sport on which the event was triggered
 */
export type Sport = "fotball" | "ishockey" | "haandball";

/**
 * The sub page on which the event was triggered
 */
export type SubPageType = "lag" | "oversikt" | "referat" | "stats" | "tabell" | "spillerstall" | "tabeller" | "karriere";

/**
 * Schema for tracking video playback endings or skips.
 */
export type Tv2NoVideoPlaybackEndedOrSkipped = {
    /**
     * The unique identifier for the video asset.
     */
    assetId: string;
    /**
     * The name of the video asset.
     */
    assetName: string;
    /**
     * The category or type of action.
     */
    categoryTypeOfAction: string;
    /**
     * The playback direction at the end
     */
    direction: Direction;
    /**
     * The total duration of the video in seconds.
     */
    durationSec: number;
    /**
     * The total number of videos watched in the session.
     */
    numberOfVideosWatchedInSession: number;
    /**
     * The percentage of the video that was watched.
     */
    percentWatched: number;
    /**
     * The total number of seconds of the video that were watched.
     */
    watchedSec: number;
}

/**
 * The playback direction at the end
 */
export type Direction = "FORWARD" | "BACK";

/**
 * Schema for tracking category change interactions.
 */
export type Tv2NoCategoryChangeEvent = {
    /**
     * The category from which the user is navigating away.
     */
    fromCategoryTypeOfAction: string;
    /**
     * The new category to which the user is navigating.
     */
    toCategoryTypeOfAction: string;
}

/**
 * Schema for tracking video mute and unmute actions.
 */
export type Tv2NoVideoMute = {
    /**
     * Describes the action taken, either 'mute' or 'unmute'.
     */
    actionLabel: ActionLabel;
    /**
     * Indicates if the video is muted or unmuted.
     */
    muted: boolean;
}

/**
 * Describes the action taken, either 'mute' or 'unmute'.
 */
export type ActionLabel = "mute" | "unmute";

/**
 * Schema for a video start event
 */
export type Tv2NoVideoStart = {
}

/**
 * Data structure for paywall login impressions
 */
export type Tv2NoPaywallImpression = {
    reasonToLoginCategory: string;
    reasonToLoginName:     string;
}

/**
 * This event monitors user navigation through nested menu levels to analyze and optimize
 * menu structure.
 */
export type Tv2NoVideoMenuNavigation = {
    /**
     * Title of the current category
     */
    category_title: string;
    /**
     * Tracks the path of navigation
     */
    menu_path: string;
}

/**
 * Tracking of user login
 */
export type Tv2NoUserLogin = {
    /**
     * The user action around login
     */
    userAction?: string;
    /**
     * The id of the user
     */
    userId: null | string;
}

/**
 * Schema for video playback progress event
 */
export type Tv2NoVideoQuartile = {
    /**
     * The total percentage of the video that has been watched, represented as a string.
     */
    percentageComplete: string;
    /**
     * The current playback position in the video, represented as a string.
     */
    position: string;
    /**
     * The percentage of the video played back
     */
    pulsePercent: string;
}

/**
 * Creates a Snowplow Event Specification entity.
 */
export function createEventSpecification(eventSpecification: EventSpecification){
    return {
        schema:
            'iglu:com.snowplowanalytics.snowplow/event_specification/jsonschema/1-0-2',
        data: eventSpecification,
    }
}

/**
 * Automatically attached context for event specifications
 */
interface EventSpecification {
    id: string;
    name: string;
    data_product_id: string;
    data_product_name: string;
}


/**
 * Track a Snowplow event for Tv2NoPage.
 * Page entity for tv2.no
 */
export function trackTv2NoPage<T extends {} = any>(tv2NoPage: Tv2NoPage & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoPage;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_page/jsonschema/2-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoPage entity.
 */
export function createTv2NoPage(tv2NoPage: Tv2NoPage){
    return {
        schema: 'iglu:no.tv2/tv2no_page/jsonschema/2-0-0',
        data: tv2NoPage
    }
}
/**
 * Track a Snowplow event for Tv2NoVideoNavigationEvent.
 * Schema for tracking user navigation between videos.
 */
export function trackTv2NoVideoNavigationEvent<T extends {} = any>(tv2NoVideoNavigationEvent: Tv2NoVideoNavigationEvent & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoVideoNavigationEvent;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_video_navigation_event/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoVideoNavigationEvent entity.
 */
export function createTv2NoVideoNavigationEvent(tv2NoVideoNavigationEvent: Tv2NoVideoNavigationEvent){
    return {
        schema: 'iglu:no.tv2/tv2no_video_navigation_event/jsonschema/1-0-0',
        data: tv2NoVideoNavigationEvent
    }
}
/**
 * Track a Snowplow event for Tv2User.
 * A global user entity that should be added to all events tracked, either as a global context or individually for each event
 */
export function trackTv2User<T extends {} = any>(tv2User: Tv2User & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2User;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2_user/jsonschema/1-0-2',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2User entity.
 */
export function createTv2User(tv2User: Tv2User){
    return {
        schema: 'iglu:no.tv2/tv2_user/jsonschema/1-0-2',
        data: tv2User
    }
}
/**
 * Track a Snowplow event for Tv2NoVideo.
 * Context schema for video metadata.
 */
export function trackTv2NoVideo<T extends {} = any>(tv2NoVideo: Tv2NoVideo & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoVideo;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_video/jsonschema/5-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoVideo entity.
 */
export function createTv2NoVideo(tv2NoVideo: Tv2NoVideo){
    return {
        schema: 'iglu:no.tv2/tv2no_video/jsonschema/5-0-0',
        data: tv2NoVideo
    }
}
/**
 * Track a Snowplow event for Tv2NoVideoPlayer.
 * Context schema for video player info.
 */
export function trackTv2NoVideoPlayer<T extends {} = any>(tv2NoVideoPlayer: Tv2NoVideoPlayer & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoVideoPlayer;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_video_player/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoVideoPlayer entity.
 */
export function createTv2NoVideoPlayer(tv2NoVideoPlayer: Tv2NoVideoPlayer){
    return {
        schema: 'iglu:no.tv2/tv2no_video_player/jsonschema/1-0-0',
        data: tv2NoVideoPlayer
    }
}
/**
 * Track a Snowplow event for Tv2NoUserAction.
 * Schema for tracking user actions
 */
export function trackTv2NoUserAction<T extends {} = any>(tv2NoUserAction: Tv2NoUserAction & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoUserAction;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_user_action/jsonschema/1-0-1',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoUserAction entity.
 */
export function createTv2NoUserAction(tv2NoUserAction: Tv2NoUserAction){
    return {
        schema: 'iglu:no.tv2/tv2no_user_action/jsonschema/1-0-1',
        data: tv2NoUserAction
    }
}
/**
 * Track a Snowplow event for Tv2Component.
 * Schema for custom components, such as BITS modules or internally developed React components that are likely to be used across multiple page types, views and domains.
 */
export function trackTv2Component<T extends {} = any>(tv2Component: Tv2Component & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2Component;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2_component/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2Component entity.
 */
export function createTv2Component(tv2Component: Tv2Component){
    return {
        schema: 'iglu:no.tv2/tv2_component/jsonschema/1-0-0',
        data: tv2Component
    }
}
/**
 * Track a Snowplow event for Tv2NoVideoEnd.
 * Schema for event when video ends
 */
export function trackTv2NoVideoEnd<T extends {} = any>(tv2NoVideoEnd: Tv2NoVideoEnd & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoVideoEnd;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_video_end/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoVideoEnd entity.
 */
export function createTv2NoVideoEnd(tv2NoVideoEnd: Tv2NoVideoEnd){
    return {
        schema: 'iglu:no.tv2/tv2no_video_end/jsonschema/1-0-0',
        data: tv2NoVideoEnd
    }
}
/**
 * Track a Snowplow event for Tv2NoVideoCloseButtonEvent.
 * Schema for tracking when a user clicks or taps the close button.
 */
export function trackTv2NoVideoCloseButtonEvent<T extends {} = any>(tv2NoVideoCloseButtonEvent: Tv2NoVideoCloseButtonEvent & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoVideoCloseButtonEvent;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_video_close_button_event/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoVideoCloseButtonEvent entity.
 */
export function createTv2NoVideoCloseButtonEvent(tv2NoVideoCloseButtonEvent: Tv2NoVideoCloseButtonEvent){
    return {
        schema: 'iglu:no.tv2/tv2no_video_close_button_event/jsonschema/1-0-0',
        data: tv2NoVideoCloseButtonEvent
    }
}
/**
 * Track a Snowplow event for Tv2NoVideoEvent.
 * Context schema for video event info.
 */
export function trackTv2NoVideoEvent<T extends {} = any>(tv2NoVideoEvent: Tv2NoVideoEvent & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoVideoEvent;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_video_event/jsonschema/1-1-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoVideoEvent entity.
 */
export function createTv2NoVideoEvent(tv2NoVideoEvent: Tv2NoVideoEvent){
    return {
        schema: 'iglu:no.tv2/tv2no_video_event/jsonschema/1-1-0',
        data: tv2NoVideoEvent
    }
}
/**
 * Track a Snowplow event for Tv2NoVideoInteraction.
 * Schema for tracking various types of video interactions, including pause and resume actions.
 */
export function trackTv2NoVideoInteraction<T extends {} = any>(tv2NoVideoInteraction: Tv2NoVideoInteraction & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoVideoInteraction;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_video_interaction/jsonschema/1-0-1',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoVideoInteraction entity.
 */
export function createTv2NoVideoInteraction(tv2NoVideoInteraction: Tv2NoVideoInteraction){
    return {
        schema: 'iglu:no.tv2/tv2no_video_interaction/jsonschema/1-0-1',
        data: tv2NoVideoInteraction
    }
}
/**
 * Track a Snowplow event for Tv2NoLivesportMatchClick.
 * Schema for tracking match click in Livesport
 */
export function trackTv2NoLivesportMatchClick<T extends {} = any>(tv2NoLivesportMatchClick: Tv2NoLivesportMatchClick & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoLivesportMatchClick;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_livesport_match_click/jsonschema/2-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoLivesportMatchClick entity.
 */
export function createTv2NoLivesportMatchClick(tv2NoLivesportMatchClick: Tv2NoLivesportMatchClick){
    return {
        schema: 'iglu:no.tv2/tv2no_livesport_match_click/jsonschema/2-0-0',
        data: tv2NoLivesportMatchClick
    }
}
/**
 * Track a Snowplow event for Tv2NoLivesport.
 * Context schema for Livesport events
 */
export function trackTv2NoLivesport<T extends {} = any>(tv2NoLivesport: Tv2NoLivesport & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoLivesport;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_livesport/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoLivesport entity.
 */
export function createTv2NoLivesport(tv2NoLivesport: Tv2NoLivesport){
    return {
        schema: 'iglu:no.tv2/tv2no_livesport/jsonschema/1-0-0',
        data: tv2NoLivesport
    }
}
/**
 * Track a Snowplow event for Tv2NoVideoPlaybackEndedOrSkipped.
 * Schema for tracking video playback endings or skips.
 */
export function trackTv2NoVideoPlaybackEndedOrSkipped<T extends {} = any>(tv2NoVideoPlaybackEndedOrSkipped: Tv2NoVideoPlaybackEndedOrSkipped & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoVideoPlaybackEndedOrSkipped;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_video_playback_ended_or_skipped/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoVideoPlaybackEndedOrSkipped entity.
 */
export function createTv2NoVideoPlaybackEndedOrSkipped(tv2NoVideoPlaybackEndedOrSkipped: Tv2NoVideoPlaybackEndedOrSkipped){
    return {
        schema: 'iglu:no.tv2/tv2no_video_playback_ended_or_skipped/jsonschema/1-0-0',
        data: tv2NoVideoPlaybackEndedOrSkipped
    }
}
/**
 * Track a Snowplow event for Tv2NoCategoryChangeEvent.
 * Schema for tracking category change interactions.
 */
export function trackTv2NoCategoryChangeEvent<T extends {} = any>(tv2NoCategoryChangeEvent: Tv2NoCategoryChangeEvent & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoCategoryChangeEvent;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_category_change_event/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoCategoryChangeEvent entity.
 */
export function createTv2NoCategoryChangeEvent(tv2NoCategoryChangeEvent: Tv2NoCategoryChangeEvent){
    return {
        schema: 'iglu:no.tv2/tv2no_category_change_event/jsonschema/1-0-0',
        data: tv2NoCategoryChangeEvent
    }
}
/**
 * Track a Snowplow event for Tv2NoVideoMute.
 * Schema for tracking video mute and unmute actions.
 */
export function trackTv2NoVideoMute<T extends {} = any>(tv2NoVideoMute: Tv2NoVideoMute & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoVideoMute;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_video_mute/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoVideoMute entity.
 */
export function createTv2NoVideoMute(tv2NoVideoMute: Tv2NoVideoMute){
    return {
        schema: 'iglu:no.tv2/tv2no_video_mute/jsonschema/1-0-0',
        data: tv2NoVideoMute
    }
}
/**
 * Track a Snowplow event for Tv2NoVideoStart.
 * Schema for a video start event
 */
export function trackTv2NoVideoStart<T extends {} = any>(tv2NoVideoStart: Tv2NoVideoStart & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoVideoStart;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_video_start/jsonschema/2-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoVideoStart entity.
 */
export function createTv2NoVideoStart(tv2NoVideoStart: Tv2NoVideoStart){
    return {
        schema: 'iglu:no.tv2/tv2no_video_start/jsonschema/2-0-0',
        data: tv2NoVideoStart
    }
}
/**
 * Track a Snowplow event for Tv2NoPaywallImpression.
 * Data structure for paywall login impressions
 */
export function trackTv2NoPaywallImpression<T extends {} = any>(tv2NoPaywallImpression: Tv2NoPaywallImpression & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoPaywallImpression;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_paywall_impression/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoPaywallImpression entity.
 */
export function createTv2NoPaywallImpression(tv2NoPaywallImpression: Tv2NoPaywallImpression){
    return {
        schema: 'iglu:no.tv2/tv2no_paywall_impression/jsonschema/1-0-0',
        data: tv2NoPaywallImpression
    }
}
/**
 * Track a Snowplow event for Tv2NoVideoMenuNavigation.
 * This event monitors user navigation through nested menu levels to analyze and optimize menu structure.
 */
export function trackTv2NoVideoMenuNavigation<T extends {} = any>(tv2NoVideoMenuNavigation: Tv2NoVideoMenuNavigation & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoVideoMenuNavigation;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_video_menu_navigation/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoVideoMenuNavigation entity.
 */
export function createTv2NoVideoMenuNavigation(tv2NoVideoMenuNavigation: Tv2NoVideoMenuNavigation){
    return {
        schema: 'iglu:no.tv2/tv2no_video_menu_navigation/jsonschema/1-0-0',
        data: tv2NoVideoMenuNavigation
    }
}
/**
 * Track a Snowplow event for Tv2NoUserLogin.
 * Tracking of user login
 */
export function trackTv2NoUserLogin<T extends {} = any>(tv2NoUserLogin: Tv2NoUserLogin & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoUserLogin;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_user_login/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoUserLogin entity.
 */
export function createTv2NoUserLogin(tv2NoUserLogin: Tv2NoUserLogin){
    return {
        schema: 'iglu:no.tv2/tv2no_user_login/jsonschema/1-0-0',
        data: tv2NoUserLogin
    }
}
/**
 * Track a Snowplow event for Tv2NoVideoQuartile.
 * Schema for video playback progress event
 */
export function trackTv2NoVideoQuartile<T extends {} = any>(tv2NoVideoQuartile: Tv2NoVideoQuartile & CommonEventProperties<T>, trackers?: string[]){
    const { context, timestamp, ...data } = tv2NoVideoQuartile;
    const event: SelfDescribingJson = {
        schema: 'iglu:no.tv2/tv2no_video_quartile/jsonschema/1-0-0',
        data
    };

    trackSelfDescribingEvent({
        event,
        context,
        timestamp,
    }, trackers);
}

/**
 * Creates a Snowplow Tv2NoVideoQuartile entity.
 */
export function createTv2NoVideoQuartile(tv2NoVideoQuartile: Tv2NoVideoQuartile){
    return {
        schema: 'iglu:no.tv2/tv2no_video_quartile/jsonschema/1-0-0',
        data: tv2NoVideoQuartile
    }
}

/**
 * Tracks a VideoNavigationEvent event specification.
 * ID: 07c87493-46fb-441c-b259-4603c71b30bc
 */
export function trackVideoNavigationEventSpec(videoNavigationEvent: Tv2NoVideoNavigationEvent & CommonEventProperties<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer>, trackers?: string[]){
    const eventSpecificationContext: SelfDescribingJson<EventSpecification> = createEventSpecification({ 
        id: '07c87493-46fb-441c-b259-4603c71b30bc',
        name: 'Video navigation event',
        data_product_id: '4e9792d1-a590-4f18-bab6-c2b3b953e981',
        data_product_name: 'TV2.NO Video Reels Tracking'
    });

    const context = Array.isArray(videoNavigationEvent.context)
        ? [...videoNavigationEvent.context, eventSpecificationContext]
        : [eventSpecificationContext];

    const modifiedVideoNavigationEvent = {
        ...videoNavigationEvent,
        context,
    };

    trackTv2NoVideoNavigationEvent<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer | EventSpecification>(modifiedVideoNavigationEvent, trackers);
}
/**
 * Tracks a UserAction event specification.
 * ID: 0de4c612-2975-45a1-b7a1-cf930f7c001e
 */
export function trackUserActionSpec(userAction: Tv2NoUserAction & CommonEventProperties<Tv2Component>, trackers?: string[]){
    const eventSpecificationContext: SelfDescribingJson<EventSpecification> = createEventSpecification({ 
        id: '0de4c612-2975-45a1-b7a1-cf930f7c001e',
        name: 'User Action',
        data_product_id: '5acd0a88-158f-4f62-8af4-56ea346043ca',
        data_product_name: 'TV2.NO BITS Component Tracker'
    });

    const context = Array.isArray(userAction.context)
        ? [...userAction.context, eventSpecificationContext]
        : [eventSpecificationContext];

    const modifiedUserAction = {
        ...userAction,
        context,
    };

    trackTv2NoUserAction<Tv2Component | EventSpecification>(modifiedUserAction, trackers);
}
/**
 * Tracks a VideoEnd event specification.
 * ID: 1454ac78-8aae-416a-bb3e-d58bbf01e8b9
 */
export function trackVideoEndSpec(videoEnd: Tv2NoVideoEnd & CommonEventProperties<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer>, trackers?: string[]){
    const eventSpecificationContext: SelfDescribingJson<EventSpecification> = createEventSpecification({ 
        id: '1454ac78-8aae-416a-bb3e-d58bbf01e8b9',
        name: 'Video end',
        data_product_id: '4e9792d1-a590-4f18-bab6-c2b3b953e981',
        data_product_name: 'TV2.NO Video Reels Tracking'
    });

    const context = Array.isArray(videoEnd.context)
        ? [...videoEnd.context, eventSpecificationContext]
        : [eventSpecificationContext];

    const modifiedVideoEnd = {
        ...videoEnd,
        context,
    };

    trackTv2NoVideoEnd<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer | EventSpecification>(modifiedVideoEnd, trackers);
}
/**
 * Tracks a VideoCloseButtonEvent event specification.
 * ID: 2c295eee-f3c5-41b0-b9d4-3ebab1dc7fe6
 */
export function trackVideoCloseButtonEventSpec(videoCloseButtonEvent: Tv2NoVideoCloseButtonEvent & CommonEventProperties<Tv2User | Tv2NoVideoEvent | Tv2NoVideoPlayer>, trackers?: string[]){
    const eventSpecificationContext: SelfDescribingJson<EventSpecification> = createEventSpecification({ 
        id: '2c295eee-f3c5-41b0-b9d4-3ebab1dc7fe6',
        name: 'Video close button event',
        data_product_id: '4e9792d1-a590-4f18-bab6-c2b3b953e981',
        data_product_name: 'TV2.NO Video Reels Tracking'
    });

    const context = Array.isArray(videoCloseButtonEvent.context)
        ? [...videoCloseButtonEvent.context, eventSpecificationContext]
        : [eventSpecificationContext];

    const modifiedVideoCloseButtonEvent = {
        ...videoCloseButtonEvent,
        context,
    };

    trackTv2NoVideoCloseButtonEvent<Tv2User | Tv2NoVideoEvent | Tv2NoVideoPlayer | EventSpecification>(modifiedVideoCloseButtonEvent, trackers);
}
/**
 * Tracks a LabUserAction event specification.
 * ID: 4038ac42-038c-49a1-8122-4e60fb215f77
 */
export function trackLabUserActionSpec(labUserAction: Tv2NoUserAction & CommonEventProperties, trackers?: string[]){
    const eventSpecificationContext: SelfDescribingJson<EventSpecification> = createEventSpecification({ 
        id: '4038ac42-038c-49a1-8122-4e60fb215f77',
        name: 'LAB User Action',
        data_product_id: '097df141-f8fb-4a82-b099-89adeef86573',
        data_product_name: 'TV2.NO Labrador/Global Tracking'
    });

    const context = Array.isArray(labUserAction.context)
        ? [...labUserAction.context, eventSpecificationContext]
        : [eventSpecificationContext];

    const modifiedLabUserAction = {
        ...labUserAction,
        context,
    };

    trackTv2NoUserAction<Record<string, unknown> | EventSpecification>(modifiedLabUserAction, trackers);
}
/**
 * Tracks a VideoInteraction event specification.
 * ID: 40bce21e-2270-44d8-9c0f-d3f445cc9351
 */
export function trackVideoInteractionSpec(videoInteraction: Tv2NoVideoInteraction & CommonEventProperties<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer>, trackers?: string[]){
    const eventSpecificationContext: SelfDescribingJson<EventSpecification> = createEventSpecification({ 
        id: '40bce21e-2270-44d8-9c0f-d3f445cc9351',
        name: 'Video interaction',
        data_product_id: '4e9792d1-a590-4f18-bab6-c2b3b953e981',
        data_product_name: 'TV2.NO Video Reels Tracking'
    });

    const context = Array.isArray(videoInteraction.context)
        ? [...videoInteraction.context, eventSpecificationContext]
        : [eventSpecificationContext];

    const modifiedVideoInteraction = {
        ...videoInteraction,
        context,
    };

    trackTv2NoVideoInteraction<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer | EventSpecification>(modifiedVideoInteraction, trackers);
}
/**
 * Tracks a MatchClick event specification.
 * ID: 66f9e3dc-326b-42a3-b82a-16a24b06637a
 */
export function trackMatchClickSpec(matchClick: Tv2NoLivesportMatchClick & CommonEventProperties<Tv2User | Tv2NoLivesport>, trackers?: string[]){
    const eventSpecificationContext: SelfDescribingJson<EventSpecification> = createEventSpecification({ 
        id: '66f9e3dc-326b-42a3-b82a-16a24b06637a',
        name: 'Match Click',
        data_product_id: '31f57dca-da9d-4b60-a74f-01d7379d2520',
        data_product_name: 'TV2.NO Livesport Tracking'
    });

    const context = Array.isArray(matchClick.context)
        ? [...matchClick.context, eventSpecificationContext]
        : [eventSpecificationContext];

    const modifiedMatchClick = {
        ...matchClick,
        context,
    };

    trackTv2NoLivesportMatchClick<Tv2User | Tv2NoLivesport | EventSpecification>(modifiedMatchClick, trackers);
}
/**
 * Tracks a VideoPlaybackEndedOrSkipped event specification.
 * ID: 8bb96e05-60ec-4f01-a9db-55aef75c1b81
 */
export function trackVideoPlaybackEndedOrSkippedSpec(videoPlaybackEndedOrSkipped: Tv2NoVideoPlaybackEndedOrSkipped & CommonEventProperties<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer>, trackers?: string[]){
    const eventSpecificationContext: SelfDescribingJson<EventSpecification> = createEventSpecification({ 
        id: '8bb96e05-60ec-4f01-a9db-55aef75c1b81',
        name: 'Video playback ended or skipped',
        data_product_id: '4e9792d1-a590-4f18-bab6-c2b3b953e981',
        data_product_name: 'TV2.NO Video Reels Tracking'
    });

    const context = Array.isArray(videoPlaybackEndedOrSkipped.context)
        ? [...videoPlaybackEndedOrSkipped.context, eventSpecificationContext]
        : [eventSpecificationContext];

    const modifiedVideoPlaybackEndedOrSkipped = {
        ...videoPlaybackEndedOrSkipped,
        context,
    };

    trackTv2NoVideoPlaybackEndedOrSkipped<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer | EventSpecification>(modifiedVideoPlaybackEndedOrSkipped, trackers);
}
/**
 * Tracks a CategoryChangeEvent event specification.
 * ID: 99e5b1f6-c85d-499b-8d16-183fa9625ec1
 */
export function trackCategoryChangeEventSpec(categoryChangeEvent: Tv2NoCategoryChangeEvent & CommonEventProperties<Tv2User | Tv2NoVideoEvent | Tv2NoVideoPlayer>, trackers?: string[]){
    const eventSpecificationContext: SelfDescribingJson<EventSpecification> = createEventSpecification({ 
        id: '99e5b1f6-c85d-499b-8d16-183fa9625ec1',
        name: 'Category change event',
        data_product_id: '4e9792d1-a590-4f18-bab6-c2b3b953e981',
        data_product_name: 'TV2.NO Video Reels Tracking'
    });

    const context = Array.isArray(categoryChangeEvent.context)
        ? [...categoryChangeEvent.context, eventSpecificationContext]
        : [eventSpecificationContext];

    const modifiedCategoryChangeEvent = {
        ...categoryChangeEvent,
        context,
    };

    trackTv2NoCategoryChangeEvent<Tv2User | Tv2NoVideoEvent | Tv2NoVideoPlayer | EventSpecification>(modifiedCategoryChangeEvent, trackers);
}
/**
 * Tracks a VideoMute event specification.
 * ID: b70d86c7-9a21-4e87-aa6c-1f3ba7aea709
 */
export function trackVideoMuteSpec(videoMute: Tv2NoVideoMute & CommonEventProperties<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer>, trackers?: string[]){
    const eventSpecificationContext: SelfDescribingJson<EventSpecification> = createEventSpecification({ 
        id: 'b70d86c7-9a21-4e87-aa6c-1f3ba7aea709',
        name: 'Video mute',
        data_product_id: '4e9792d1-a590-4f18-bab6-c2b3b953e981',
        data_product_name: 'TV2.NO Video Reels Tracking'
    });

    const context = Array.isArray(videoMute.context)
        ? [...videoMute.context, eventSpecificationContext]
        : [eventSpecificationContext];

    const modifiedVideoMute = {
        ...videoMute,
        context,
    };

    trackTv2NoVideoMute<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer | EventSpecification>(modifiedVideoMute, trackers);
}
/**
 * Tracks a VideoStart event specification.
 * ID: bca75448-3c30-44b1-b81f-43010b50c40d
 */
export function trackVideoStartSpec(videoStart: Tv2NoVideoStart & CommonEventProperties<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer>, trackers?: string[]){
    const eventSpecificationContext: SelfDescribingJson<EventSpecification> = createEventSpecification({ 
        id: 'bca75448-3c30-44b1-b81f-43010b50c40d',
        name: 'Video start',
        data_product_id: '4e9792d1-a590-4f18-bab6-c2b3b953e981',
        data_product_name: 'TV2.NO Video Reels Tracking'
    });

    const context = Array.isArray(videoStart.context)
        ? [...videoStart.context, eventSpecificationContext]
        : [eventSpecificationContext];

    const modifiedVideoStart = {
        ...videoStart,
        context,
    };

    trackTv2NoVideoStart<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer | EventSpecification>(modifiedVideoStart, trackers);
}
/**
 * Tracks a PaywallImpression event specification.
 * ID: bcde6c9e-9475-45ed-9890-df3d9e46b262
 */
export function trackPaywallImpressionSpec(paywallImpression: Tv2NoPaywallImpression & CommonEventProperties, trackers?: string[]){
    const eventSpecificationContext: SelfDescribingJson<EventSpecification> = createEventSpecification({ 
        id: 'bcde6c9e-9475-45ed-9890-df3d9e46b262',
        name: 'Paywall Impression',
        data_product_id: '097df141-f8fb-4a82-b099-89adeef86573',
        data_product_name: 'TV2.NO Labrador/Global Tracking'
    });

    const context = Array.isArray(paywallImpression.context)
        ? [...paywallImpression.context, eventSpecificationContext]
        : [eventSpecificationContext];

    const modifiedPaywallImpression = {
        ...paywallImpression,
        context,
    };

    trackTv2NoPaywallImpression<Record<string, unknown> | EventSpecification>(modifiedPaywallImpression, trackers);
}
/**
 * Tracks a VideoMenuNavigation event specification.
 * ID: c0dea4b6-5336-4672-bdc8-cb6ad9614749
 */
export function trackVideoMenuNavigationSpec(videoMenuNavigation: Tv2NoVideoMenuNavigation & CommonEventProperties<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer>, trackers?: string[]){
    const eventSpecificationContext: SelfDescribingJson<EventSpecification> = createEventSpecification({ 
        id: 'c0dea4b6-5336-4672-bdc8-cb6ad9614749',
        name: 'Video menu navigation',
        data_product_id: '4e9792d1-a590-4f18-bab6-c2b3b953e981',
        data_product_name: 'TV2.NO Video Reels Tracking'
    });

    const context = Array.isArray(videoMenuNavigation.context)
        ? [...videoMenuNavigation.context, eventSpecificationContext]
        : [eventSpecificationContext];

    const modifiedVideoMenuNavigation = {
        ...videoMenuNavigation,
        context,
    };

    trackTv2NoVideoMenuNavigation<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer | EventSpecification>(modifiedVideoMenuNavigation, trackers);
}
/**
 * Tracks a UserLogin event specification.
 * ID: e3e8ca0c-faa0-4763-9016-32e4a813e4d5
 */
export function trackUserLoginSpec(userLogin: Tv2NoUserLogin & CommonEventProperties, trackers?: string[]){
    const eventSpecificationContext: SelfDescribingJson<EventSpecification> = createEventSpecification({ 
        id: 'e3e8ca0c-faa0-4763-9016-32e4a813e4d5',
        name: 'User login',
        data_product_id: '097df141-f8fb-4a82-b099-89adeef86573',
        data_product_name: 'TV2.NO Labrador/Global Tracking'
    });

    const context = Array.isArray(userLogin.context)
        ? [...userLogin.context, eventSpecificationContext]
        : [eventSpecificationContext];

    const modifiedUserLogin = {
        ...userLogin,
        context,
    };

    trackTv2NoUserLogin<Record<string, unknown> | EventSpecification>(modifiedUserLogin, trackers);
}
/**
 * Tracks a VideoQuartile event specification.
 * ID: eb568b7e-a559-41c6-b1fd-4d25c047d4ba
 */
export function trackVideoQuartileSpec(videoQuartile: Tv2NoVideoQuartile & CommonEventProperties<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer>, trackers?: string[]){
    const eventSpecificationContext: SelfDescribingJson<EventSpecification> = createEventSpecification({ 
        id: 'eb568b7e-a559-41c6-b1fd-4d25c047d4ba',
        name: 'Video quartile',
        data_product_id: '4e9792d1-a590-4f18-bab6-c2b3b953e981',
        data_product_name: 'TV2.NO Video Reels Tracking'
    });

    const context = Array.isArray(videoQuartile.context)
        ? [...videoQuartile.context, eventSpecificationContext]
        : [eventSpecificationContext];

    const modifiedVideoQuartile = {
        ...videoQuartile,
        context,
    };

    trackTv2NoVideoQuartile<Tv2User | Tv2NoVideo | Tv2NoVideoPlayer | EventSpecification>(modifiedVideoQuartile, trackers);
}

