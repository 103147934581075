import { addGlobalContexts, removeGlobalContexts, SelfDescribingJson } from "@snowplow/browser-tracker";
import { v4 as uuidv4 } from "uuid";
import {
  Tv2NoVideo,
  Tv2NoVideoCloseButtonEvent,
  Tv2NoVideoInteraction,
  Tv2NoVideoMenuNavigation,
  Tv2NoVideoMute,
  Tv2NoVideoNavigationEvent,
  Tv2NoVideoPlaybackEndedOrSkipped,
  Tv2NoVideoPlayer,
  Tv2NoVideoQuartile,
  createTv2NoVideo,
  createTv2NoVideoPlayer,
  trackVideoCloseButtonEventSpec,
  trackVideoEndSpec,
  trackVideoInteractionSpec,
  trackVideoMenuNavigationSpec,
  trackVideoMuteSpec,
  trackVideoNavigationEventSpec,
  trackVideoPlaybackEndedOrSkippedSpec,
  trackVideoQuartileSpec,
  trackVideoStartSpec,
} from "../../snowtype/snowplow";
import { buildVideoInfoContext, buildVideoPlayerInfoContext } from "../helpers/contextBuilders";
import { Logger } from "../helpers/logger";
import { IVideoPulses } from "../types";
import { IVideoTracker, SetAssetParams } from "./IVideoTracker";

export class VideoTracker implements IVideoTracker {
  private videoSessionId: string = uuidv4();
  private videoPulses: IVideoPulses | undefined = undefined;
  private currentVideoContexts: SelfDescribingJson<Tv2NoVideo | Tv2NoVideoPlayer>[] = [];

  setAsset(params: SetAssetParams): void {
    this.videoPulses = {
      firstQuartileFired: false,
      secondQuartileFired: false,
      thirdQuartileFired: false,
    };
    Logger.log("Setting asset for video tracking:", params.asset);
    this.updateVideoContexts(params);
  }

  private updateVideoContexts({ asset, category, numberOfVideosWatched }: SetAssetParams): void {
    if (this.currentVideoContexts.length > 0) {
      removeGlobalContexts(this.currentVideoContexts);
    }

    const videoInfoContext = {
      asset,
      category,
      numberOfVideosWatched,
      videoSessionId: this.videoSessionId,
    };
    const videoEventContext = createTv2NoVideo(buildVideoInfoContext(videoInfoContext));
    const videoPlayerContext = createTv2NoVideoPlayer(buildVideoPlayerInfoContext());

    this.currentVideoContexts = [videoEventContext, videoPlayerContext];

    addGlobalContexts(this.currentVideoContexts);
  }

  videoStart(): void {
    try {
      trackVideoStartSpec({});
      Logger.debug("Logging video start event");
    } catch (error) {
      Logger.error("Error tracking video start event:", error);
    }
  }

  videoProgress = (pctCurrentAsset: number, position: number, duration: number): void => {
    if (!this.videoPulses) {
      this.videoPulses = {
        firstQuartileFired: false,
        secondQuartileFired: false,
        thirdQuartileFired: false,
      };
    }

    const trackQuartileEvent = (pulseValue: number) => {
      const positionRounded = Math.round(position);
      const percentageComplete = duration > 0 ? Math.round((positionRounded / duration) * 100) : 0;
      const data: Tv2NoVideoQuartile = {
        pulsePercent: `${pulseValue}%`,
        position: `${positionRounded}`,
        percentageComplete: `${percentageComplete}%`,
      };
      trackVideoQuartileSpec(data);
    };

    if (pctCurrentAsset >= 25 && !this.videoPulses.firstQuartileFired) {
      this.videoPulses.firstQuartileFired = true;
      trackQuartileEvent(25);
    }
    if (pctCurrentAsset >= 50 && !this.videoPulses.secondQuartileFired) {
      this.videoPulses.secondQuartileFired = true;
      trackQuartileEvent(50);
    }
    if (pctCurrentAsset >= 75 && !this.videoPulses.thirdQuartileFired) {
      this.videoPulses.thirdQuartileFired = true;
      trackQuartileEvent(75);
    }
  };

  videoInteraction(data: Tv2NoVideoInteraction): void {
    try {
      this.trackVideoInteraction(data);
    } catch (error) {
      Logger.error(`Error tracking video ${data.interactionType} event:`, error);
    }
  }

  videoMute(muted: boolean): void {
    try {
      const actionLabel = muted ? "mute" : "unmute";
      const data: Tv2NoVideoMute = {
        muted,
        actionLabel,
      };
      trackVideoMuteSpec(data);
      Logger.debug(`Video ${actionLabel} event tracked`, data);
    } catch (error) {
      Logger.error(`Error tracking video ${muted ? "mute" : "unmute"} event:`, error);
    }
  }

  videoEnd(): void {
    try {
      trackVideoEndSpec({});
      Logger.debug(`Video end event tracked`);
    } catch (error) {
      Logger.error(`Error tracking video end event:`, error);
    }
  }

  videoPlaybackEndedOrSkipped(data: Tv2NoVideoPlaybackEndedOrSkipped): void {
    try {
      trackVideoPlaybackEndedOrSkippedSpec(data);
      Logger.debug(`Video playback ended or skipped event tracked:`, data);
    } catch (error) {
      Logger.error(`Error tracking video playback ended or skipped event:`, error);
    }
  }

  navigateBetweenVideos(label: string): void {
    try {
      const data: Tv2NoVideoNavigationEvent = {
        videoLabel: label,
      };

      trackVideoNavigationEventSpec(data);
      Logger.debug("Navigating between videos");
    } catch (error) {
      Logger.error("Error navigating between videos:", error);
    }
  }

  closeButtonClickedOrTapped(data: Tv2NoVideoCloseButtonEvent): void {
    try {
      trackVideoCloseButtonEventSpec(data);
      Logger.debug("Close navigation button clicked or tapped");
    } catch (error) {
      Logger.error("Error tracking close category button:", error);
    }
  }

  menuNavigation(data: Tv2NoVideoMenuNavigation): void {
    try {
      trackVideoMenuNavigationSpec(data);
      Logger.debug("Menu navigation event tracked:", data);
    } catch (error) {
      Logger.error("Error tracking menu navigation event:", error);
    }
  }

  trackVideoInteraction(data: Tv2NoVideoInteraction): void {
    try {
      trackVideoInteractionSpec(data);

      Logger.debug(`Video ${data.interactionType} event tracked:`, data);
    } catch (error) {
      Logger.error(`Error tracking video \${interactionType} event:`, error);
    }
  }
}
