import { CoreTracker, ICoreTracker } from './CoreTracker';
import { VideoTracker, IVideoTracker } from './VideoTracker';
import { LivesportTracker, ILivesportTracker } from './LivesportTracker';
import { ComponentTracker, IComponentTracker } from './ComponentTracker';
import { SnowplowTrackingOptions } from './types';
import { Logger } from './helpers/logger';

export interface ISnowplowTracker {
  /**
   * Core tracker that includes all core snowplow tracking functionalities.
   */
  coreTracker: ICoreTracker;
  /**
   * Video tracker that includes all video tracking functionalities.
   */
  videoTracker: IVideoTracker;
  /**
   * Livesport tracker that includes all livesport tracking functionalities.
   */
  livesportTracker: ILivesportTracker;
  /**
   * Component tracker
   */
  componentTracker: IComponentTracker;

  /**
   * Initializes the snowplow tracker.
   */
  initialize(): Promise<void>;
}

/**
 * Tracker class that consolidates core and video tracking functionalities.
 * This class ensures that all tracking related actions are managed through a single interface,
 * enhancing maintainability and scalability.
 */
export class SnowplowTracker implements ISnowplowTracker {
  public coreTracker: ICoreTracker;
  public videoTracker: IVideoTracker;
  public livesportTracker: ILivesportTracker;
  public componentTracker: IComponentTracker;

  private static instance: SnowplowTracker;
  private initializedPromise: Promise<void> | null = null;

  constructor(options: SnowplowTrackingOptions = {}) {
    const { logLevel = 'WARN' } = options;
    Logger.setLogLevel(logLevel);
    this.coreTracker = new CoreTracker(options);
    this.videoTracker = new VideoTracker();
    this.livesportTracker = new LivesportTracker();
    this.componentTracker = new ComponentTracker();
  }

  public static getInstance(options: SnowplowTrackingOptions = {}): SnowplowTracker {
    if (typeof window !== 'undefined' && window.snowplowTracker) {
      Logger.warn('Snowplow tracker already initialized');
      return window.snowplowTracker;
    }

    if (!SnowplowTracker.instance) {
      SnowplowTracker.instance = new SnowplowTracker(options);
      if (typeof window !== 'undefined') {
        Logger.info('Snowplow tracker initialized');
        window.snowplowTracker = SnowplowTracker.instance;
      }
    }
    return SnowplowTracker.instance;
  }

  public initialize(): Promise<void> {
    if (this.initializedPromise) {
      return this.initializedPromise;
    }

    this.initializedPromise = this.initializeInternal();
    return this.initializedPromise;
  }

  private async initializeInternal(): Promise<void> {
    await this.coreTracker.initializeTracker();

    this.coreTracker.enableActivityTracking();
    this.coreTracker.enableButtonClickTracking();
    this.coreTracker.enableLinkClickTracking();

    const metadataContext = this.getMetadataContext();
    if (metadataContext) {
      this.coreTracker.setGlobalContexts([metadataContext]);
    }

    this.coreTracker.trackPageView({ shouldBuffer: true });

    await new Promise<void>((resolve) => {
      this.coreTracker.setupUserContext(() => {
        this.coreTracker.processBufferedEvents();
        resolve();
      });
    });

    Logger.info('Snowplow tracker fully initialized');
  }

  private getMetadataContext(): { schema: string; data: any } | null {
    if (typeof window !== 'undefined' && window.lab_metadata) {
      return {
        schema: "iglu:no.tv2/tv2no_labrador_metadata/jsonschema/1-0-4",
        data: window.lab_metadata
      };
    }
    return null;
  }
}